import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver"; // Install file-saver for downloading files

import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  LinearProgress,
  Tooltip,
  CssBaseline,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DescriptionIcon from "@mui/icons-material/Description";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/system";
import CodeIcon from "@mui/icons-material/Code";
import BrushIcon from "@mui/icons-material/Brush";
import WebIcon from "@mui/icons-material/Web";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import StorageIcon from "@mui/icons-material/Storage";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import LeadershipIcon from "@mui/icons-material/EmojiEvents";
import CommunicationIcon from "@mui/icons-material/Forum";
import CreativityIcon from "@mui/icons-material/Lightbulb";

// Dark theme configuration
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#6fd943',
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    text: {
      primary: '#ffffff',
      secondary: '#bbbbbb',
    },
  },
  typography: {
    fontFamily: 'Cairo, sans-serif',
  },
});

// Styled components for progress bar animation
const AnimatedProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20, // Thicker sliders
  borderRadius: 5,
  backgroundColor: "#424242",
  overflow: "hidden",
  "& .MuiLinearProgress-bar": {
    backgroundColor: "#6fd943", // Slider color
    animation: `1.5s forwards ease-in-out`,
  },
}));

// Styled component for skill icons with hover animation
const SkillIcon = styled("div")(({ theme }) => ({
  marginRight: theme.spacing(2),
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.2)",
  },
}));


const EmployeeDetail = () => {
  const { id } = useParams(); // Employee ID from the route
  const navigate = useNavigate();
  const [employee, setEmployee] = useState(null);
  const [deductions, setDeductions] = useState([]); // Initialize deductions

  const [skills, setSkills] = useState([]);
  const [disciplineSkills, setDisciplineSkills] = useState([]); // State for discipline skills
  const [globalHierarchy, setGlobalHierarchy] = useState([]);
  const [departmentHierarchy, setDepartmentHierarchy] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentCertificate, setCurrentCertificate] = useState(null);
  const [dialogType, setDialogType] = useState(""); // Add a state for dialog type
  const [acceptedLeaveCount, setAcceptedLeaveCount] = useState(0);
  const [leaveRequests, setLeaveRequests] = useState([]); // Store all leave requests for the current month
  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState(null); // Store the selected leave request
  const [permissions, setPermissions] = useState([]); // Store all permissions for the current month
  const [selectedPermission, setSelectedPermission] = useState(null); // Store the selected permission
  const [attendanceRecords, setAttendanceRecords] = useState([]); // State for attendance records

  useEffect(() => {
    fetchEmployee();
  }, []);

  useEffect(() => {
    if (employee) {
      fetchGlobalHierarchy();
      fetchDepartmentHierarchy(employee.department2);
      fetchEmployeeSkills(employee.employee_id); // Fetch skills for the employee
      fetchDisciplineSkills(employee.employee_id); // Fetch discipline skills
      fetchLeaveRequests(employee.username); // Fetch leave requests for the employee
      fetchPermissions(employee.username); // Fetch permissions for the employee
      fetchAttendanceRecords(employee.username); // Fetch attendance records for the employee
    }
  }, [employee]);
    // Function to calculate total deductions
    const calculateTotalDeductions = () => {
      return deductions.reduce((acc, deduction) => acc + parseFloat(deduction.deduction), 0);
    };
 // Function to download the monthly report as a .txt file
 const downloadMonthlyReport = () => {
  const salary = parseFloat(employee.salary); // Ensure salary is a number
  const totalDeductions = calculateTotalDeductions();
  const salaryAfterDeductions = salary - totalDeductions;

  if (isNaN(salary)) {
    alert("Salary data is not valid."); // Handle case where salary is not valid
    return;
  }

  // Build the report content as HTML
  let reportContent = `
    <!DOCTYPE html>
    <html lang="ar" dir="rtl">
    <head>
      <meta charset="UTF-8">
      <title>تقرير شهري للموظف</title>
      <style>
        body {
          font-family: 'Cairo', sans-serif;
          text-align: right;
        }
        h1 {
          color: #6fd943;
        }
        table {
          width: 100%;
          border-collapse: collapse;
        }
        th, td {
          padding: 10px;
          border: 1px solid #ddd;
        }
      </style>
    </head>
    <body>
      <h1>تقرير شهري للموظف</h1>
      <p><strong>اسم الموظف:</strong> ${employee.name}</p>
      <p><strong>الراتب الأساسي:</strong> ${salary.toFixed(2)} جنيه</p>
      <hr>
      <h2>تفاصيل الخصومات:</h2>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>التاريخ</th>
            <th>الوقت</th>
            <th>نوع الخصم</th>
            <th>قيمة الخصم</th>
          </tr>
        </thead>
        <tbody>
  `;

  deductions.forEach((deduction, index) => {
    reportContent += `
      <tr>
        <td>${index + 1}</td>
        <td>${deduction.date}</td>
        <td>${deduction.time}</td>
        <td>${deduction.reason}</td>
        <td>${deduction.deduction} جنيه</td>
      </tr>
    `;
  });

  reportContent += `
        </tbody>
      </table>
      <hr>
      <p><strong>إجمالي الخصومات:</strong> ${totalDeductions.toFixed(2)} جنيه</p>
      <p><strong>الراتب بعد الخصومات:</strong> ${salaryAfterDeductions.toFixed(2)} جنيه</p>
    </body>
    </html>
  `;

  const blob = new Blob([reportContent], { type: "text/html;charset=utf-8" });
  saveAs(blob, `Monthly_Report_${employee.name}.html`);
};




  const fetchEmployee = async () => {
    try {
      const response = await fetch(
        `https://backedndforelbatt.elbatt.com/getemployee.php?id=${id}`
      );
      const result = await response.json();
      if (result.status === "success") {
        const employeeData = result.data;
        employeeData.certificates = JSON.parse(
          employeeData.certificates || "[]"
        );
        setEmployee(employeeData);
      } else {
        setError("Failed to fetch employee: " + result.message);
      }
    } catch (error) {
      setError("Error fetching employee: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchEmployeeSkills = async (employeeId) => {
    try {
      const response = await fetch(
        `https://backedndforelbatt.elbatt.com/getSkills.php?employeeId=${employeeId}`
      );
      const result = await response.json();
      if (result.success) {
        setSkills(result.skills);
      } else {
      }
    } catch (error) {
    }
  };

  const fetchDisciplineSkills = async (employeeId) => {
    try {
      const response = await fetch(
        `https://backedndforelbatt.elbatt.com/getDisciplineSkills.php?employeeId=${employeeId}`
      );
      const result = await response.json();
      if (result.success) {
        setDisciplineSkills(result.skills);
      } else {
      }
    } catch (error) {
    }
  };

  const fetchGlobalHierarchy = async () => {
    try {
      const response = await fetch(
        `https://backedndforelbatt.elbatt.com/gethierarchy.php?global=true`
      );
      const result = await response.json();
      if (result.status === "success") {
        setGlobalHierarchy(result.data);
      } else {
        setError("Failed to fetch global hierarchy: " + result.message);
      }
    } catch (error) {
      setError("Error fetching global hierarchy: " + error.message);
    }
  };

  const fetchDepartmentHierarchy = async (department) => {
    try {
      const response = await fetch(
        `https://backedndforelbatt.elbatt.com/gethierarchy.php?department=${department}`
      );
      const result = await response.json();
      if (result.status === "success") {
        setDepartmentHierarchy(result.data);
      } else {
        setError("Failed to fetch department hierarchy: " + result.message);
      }
    } catch (error) {
      setError("Error fetching department hierarchy: " + error.message);
    }
  };

  const fetchLeaveRequests = async (username) => {
    try {
      const response = await fetch(
        `https://backedndforelbatt.elbatt.com/getleaveRequest.php`
      );
      const result = await response.json();
      if (result.success) {
        const currentMonth = new Date().getMonth() + 1; // Get the current month (0-based index, so add 1)
        const currentYear = new Date().getFullYear(); // Get the current year

        const filteredRequests = result.data.filter((request) => {
          const startDate = new Date(request.start_date);
          return (
            request.username === username &&
            request.is_accepted === 1 &&
            startDate.getMonth() + 1 === currentMonth && // Check if the month matches
            startDate.getFullYear() === currentYear // Check if the year matches
          );
        });
        setLeaveRequests(filteredRequests); // Store filtered requests
        setAcceptedLeaveCount(filteredRequests.length);
      } else {
      }
    } catch (error) {
    }
  };

  const fetchPermissions = async (username) => {
    try {
      const response = await fetch(
        `https://backedndforelbatt.elbatt.com/getPermissions.php`
      );
      const result = await response.json();
      if (result.success) {
        const filteredPermissions = result.data.filter(
          (permission) => permission.username === username && permission.is_accepted === 1
        );
        setPermissions(filteredPermissions);
      } else {
      }
    } catch (error) {
    }
  };

  const fetchAttendanceRecords = async (username) => {
    try {
      const response = await fetch(
        `https://backedndforelbatt.elbatt.com/get_attendance_month.php`
      );
      const result = await response.json();
      if (result.success) {
        const userAttendance = result.records.filter(
          (record) => record.username === username
        );
        setAttendanceRecords(userAttendance);
        calculateDeductions(userAttendance); // Calculate deductions based on attendance
      } else {
      }
    } catch (error) {
    }
  };

  const calculateDeductions = (records) => {
    const deductions = [];
  
    // Define thresholds for most employees
    const generalThresholdTime1 = new Date('1970-01-01T09:25:00Z'); // Lateness starts at 9:25 for others
    const generalThresholdTime2 = new Date('1970-01-01T09:30:00Z'); // Half-day deduction starts at 9:30 for others
  
    // Define thresholds for "nourhan"
    const nourhanThresholdTime1 = new Date('1970-01-01T09:30:00Z'); // Lateness starts at 9:30 for nourhan
    const nourhanThresholdTime2 = new Date('1970-01-01T09:35:00Z'); // Half-day deduction starts at 9:35 for nourhan
  
    records.forEach((record) => {
      const attendanceTime = new Date(`1970-01-01T${record.time}Z`);
      let thresholdTime1 = generalThresholdTime1;
      let thresholdTime2 = generalThresholdTime2;
  
      // Apply special thresholds for "nourhan"
      if (record.username === 'nourhan') {
        thresholdTime1 = nourhanThresholdTime1;
        thresholdTime2 = nourhanThresholdTime2;
      }
  
      if (record.notexist === 1) {
        // If the employee is absent
        const fullDayDeduction = (employee.salary / 30).toFixed(2);
        deductions.push({
          date: record.date,
          time: "Absent",
          deduction: fullDayDeduction,
          reason: "خصم يوم كامل - غائب",
        });
      } else if (attendanceTime > thresholdTime1 && attendanceTime <= thresholdTime2) {
        // Late between 09:25 and 09:30 for others, or 09:30 and 09:35 for "nourhan", deduct quarter day
        const quarterDayDeduction = (employee.salary / 30 / 4).toFixed(2);
        deductions.push({
          date: record.date,
          time: record.time,
          deduction: quarterDayDeduction,
          reason: "مخصوم ربع يوم",
        });
      } else if (attendanceTime > thresholdTime2) {
        // Late after 09:30 for others, or after 09:35 for "nourhan", deduct half day
        const halfDayDeduction = (employee.salary / 30 / 2).toFixed(2);
        deductions.push({
          date: record.date,
          time: record.time,
          deduction: halfDayDeduction,
          reason: "مخصوم نصف يوم",
        });
      }
    });
  
    setDeductions(deductions);
  };
  
  
  

  const handleLeaveRequestClick = (leaveRequest) => {
    setSelectedLeaveRequest(leaveRequest);
    setSelectedPermission(null); // Clear selected permission
    setCurrentCertificate(null); // Clear the current certificate
    setDialogType("leaveRequest"); // Set the dialog type
    setOpenDialog(true);
  };

  const handlePermissionClick = (permission) => {
    setSelectedPermission(permission);
    setSelectedLeaveRequest(null); // Clear selected leave request
    setCurrentCertificate(null); // Clear the current certificate
    setDialogType("permission"); // Set the dialog type
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedLeaveRequest(null);
    setSelectedPermission(null);
    setCurrentCertificate(null);
    setDialogType(""); // Reset the dialog type
  };

  const handleOpenDialog = (certificate) => {
    setCurrentCertificate(certificate);
    setSelectedLeaveRequest(null); // Clear selected leave request
    setSelectedPermission(null); // Clear selected permission
    setDialogType("certificate"); // Set the dialog type
    setOpenDialog(true);
  };

  const roleOrder = ["Head", "Team Leader", "Senior", "Mid-Level", "Junior"];

  const getRoleOrder = (role) => {
    return roleOrder.indexOf(role);
  };

  const sortedHierarchy = [...departmentHierarchy].sort((a, b) => {
    return getRoleOrder(a.role) - getRoleOrder(b.role);
  });

  const canPreviewFile = (filename) => {
    return (
      filename.endsWith(".pdf") ||
      filename.endsWith(".docx") ||
      filename.endsWith(".ppt") ||
      filename.endsWith(".pptx")
    );
  };

  const calculateHoursDifference = (startTime, endTime) => {
    const start = new Date(`1970-01-01T${startTime}Z`);
    const end = new Date(`1970-01-01T${endTime}Z`);
    const diff = (end - start) / 1000 / 60 / 60; // difference in hours
    return diff.toFixed(2);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!employee) {
    return <div>No employee data available</div>;
  }

  const cardStyle = {
    padding: "16px",
    margin: "8px",
    borderRadius: "10px",
    boxShadow: "0 4px 16px rgba(0,0,0,0.2)",
    backgroundColor: darkTheme.palette.background.paper,
    color: darkTheme.palette.text.primary,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start", // Align content to the start
    justifyContent: "flex-start", // Justify content at the start
    textAlign: "right",
  };

  const cardStyle2 = {
    ...cardStyle,
    textAlign: "center",
    width: "300px", // Fixed width (optional, you can adjust or remove it)
  };

  const activeCardStyle = {
    ...cardStyle2,
    border: `2px solid ${darkTheme.palette.primary.main}`,
  };

  const headerStyle = {
    color: darkTheme.palette.primary.main,
    textAlign: "right",
    marginBottom: "16px",
  };

  const typographyStyle = {
    color: darkTheme.palette.text.secondary,
    textAlign: "right",
    marginBottom: "8px",
  };

  const keyStyle = {
    color: darkTheme.palette.primary.main,
    fontWeight: "bold",
    display: "inline",
    marginRight: "4px",
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div style={{ padding: "16px", direction: "rtl" }}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
          <Typography
            variant="h4"
            style={{
              ...headerStyle,
              flexGrow: 1,
            }}
          >
            تفاصيل الموظف
          </Typography>
          <IconButton
  onClick={() => navigate("/hr-dashboard?view=EmployeeView")}
  style={{
    backgroundColor: darkTheme.palette.primary.main,
    color: "#ffffff",
    borderRadius: "50%",
  }}
>
  <ArrowBackIcon />
</IconButton>

        </div>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Paper
              style={{
                ...cardStyle,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Avatar
                  alt={employee.name}
                  src={`https://backedndforelbatt.elbatt.com/${employee.photo}`}
                  style={{ width: "80px", height: "80px", marginBottom: "8px" }}
                />
                <Typography
                  variant="h6"
                  style={{ ...headerStyle, textAlign: "center" }}
                >
                  {employee.name}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ ...typographyStyle, textAlign: "center" }}
                >
                  <span style={keyStyle}>المنصب:</span> {employee.role}
                </Typography>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12} md={8}>
            <Paper style={cardStyle}>
              <Typography variant="h6" style={headerStyle}>
                تفاصيل الشركة
              </Typography>
              <Typography variant="body1" style={typographyStyle}>
                <span style={keyStyle}>فرع:</span> {employee.branch}
              </Typography>
              <Typography variant="body1" style={typographyStyle}>
                <span style={keyStyle}>الادارة:</span> {employee.department}
              </Typography>
              <Typography variant="body1" style={typographyStyle}>
                <span style={keyStyle}>القسم:</span> {employee.department2}
              </Typography>
              <Typography variant="body1" style={typographyStyle}>
                <span style={keyStyle}>التسمية:</span> {employee.role}
              </Typography>
              <Typography variant="body1" style={typographyStyle}>
                <span style={keyStyle}>تاريخ الانضمام:</span>{" "}
                {employee.joining_date}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6} container spacing={3} alignItems="stretch">
            <Grid item xs={12}>
              <Paper style={cardStyle}>
                <Typography variant="h6" style={headerStyle}>
                  تفاصيل شخصية
                </Typography>
                <Typography variant="body1" style={typographyStyle}>
                  <span style={keyStyle}>كود الموظف:</span> {employee.employee_code}
                </Typography>
                <Typography variant="body1" style={typographyStyle}>
                  <span style={keyStyle}>الاسم:</span> {employee.name}
                </Typography>
                <Typography variant="body1" style={typographyStyle}>
                  <span style={keyStyle}>تاريخ الميلاد:</span>{" "}
                  {employee.date_of_birth}
                </Typography>
                <Typography variant="body1" style={typographyStyle}>
                  <span style={keyStyle}>البريد الالكتروني:</span> {employee.email}
                </Typography>
                <Typography variant="body1" style={typographyStyle}>
                  <span style={keyStyle}>العنوان:</span> {employee.address}
                </Typography>
                <Typography variant="body1" style={typographyStyle}>
                  <span style={keyStyle}>الجنس:</span> {employee.gender}
                </Typography>
                <Typography variant="body1" style={typographyStyle}>
                  <span style={keyStyle}>الراتب:</span> {employee.salary}
                </Typography>
              </Paper>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper style={cardStyle}>
              <Typography variant="h6" style={headerStyle}>
                تفاصيل الحساب المصرفي
              </Typography>
              <Typography variant="body1" style={typographyStyle}>
                <span style={keyStyle}>رقم الحساب:</span> {employee.account_number}
              </Typography>
              <Typography variant="body1" style={typographyStyle}>
                <span style={keyStyle}>اسم صاحب الحساب:</span>{" "}
                {employee.account_holder_name}
              </Typography>
              <Typography variant="body1" style={typographyStyle}>
                <span style={keyStyle}>اسم البنك:</span> {employee.bank_name}
              </Typography>
              <Typography variant="body1" style={typographyStyle}>
                <span style={keyStyle}>كود تعريف البنك:</span>{" "}
                {employee.bank_identifier_code}
              </Typography>
              <Typography variant="body1" style={typographyStyle}>
                <span style={keyStyle}>كود دافع الضريبة:</span> {employee.tax_payer_id}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper style={{ ...cardStyle, padding: "24px" }}>
              <Typography variant="h6" style={headerStyle}>
                التسلسل الهرمي
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                width="100%"
              >
                {roleOrder.map((role, index) => {
                  const currentRoleEmployees = sortedHierarchy.filter(
                    (person) => person.role === role
                  );
                  const nextRole = roleOrder[index + 1];
                  const hasNextRoleEmployees = sortedHierarchy.some(
                    (person) => person.role === nextRole
                  );

                  return (
                    <React.Fragment key={role}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                        flexWrap="wrap"
                      >
                        {currentRoleEmployees.map((person, personIndex) => (
                          <Paper
                            key={personIndex}
                            style={
                              person.name === employee.name
                                ? activeCardStyle
                                : cardStyle2
                            }
                          >
                            <Avatar
                              alt={person.name}
                              src={`https://backedndforelbatt.elbatt.com/${person.photo}`}
                              style={{
                                width: "40px",
                                height: "40px",
                                marginBottom: "8px",
                              }}
                            />
                            <Typography variant="body1" style={typographyStyle}>
                              {person.name}
                            </Typography>
                            <Typography variant="body2" style={typographyStyle}>
                              {person.role}
                            </Typography>
                          </Paper>
                        ))}
                      </Box>
                      {hasNextRoleEmployees && index < roleOrder.length - 1 && (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                        >
                          <ArrowDownwardIcon
                            style={{ color: "#6fd943", fontSize: "30px" }}
                          />
                        </Box>
                      )}
                    </React.Fragment>
                  );
                })}
              </Box>
            </Paper>
          </Grid>

          {/* New Section for Skills */}
          <Grid item xs={12}>
            <Paper style={{ ...cardStyle, padding: "24px" }}>
              <Typography variant="h6" style={headerStyle}>
                المهارات التقنية
              </Typography>
              <Grid container spacing={2}>
                {skills.map((skill, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <Tooltip
                      title={`${skill.skill_name} proficiency level`}
                      arrow
                    >
                      <Box display="flex" alignItems="center">
                        <SkillIcon style={{ cursor: "pointer" }}>
                          {getSkillIcon(skill.skill_name)}
                        </SkillIcon>
                        <Typography
                          variant="body1"
                          style={{
                            fontFamily: "Cairo, sans-serif",
                            marginBottom: "4px",
                            color: darkTheme.palette.text.secondary,
                            width: "120px",
                            flexShrink: 0,
                          }}
                        >
                          {skill.skill_name}
                        </Typography>
                        <Box flexGrow={1} mx={2}>
                          <AnimatedProgress
                            variant="determinate"
                            value={skill.percentage}
                          />
                        </Box>
                        <Typography
                          variant="body2"
                          style={{
                            fontFamily: "Cairo, sans-serif",
                            color: darkTheme.palette.text.secondary,
                            marginTop: "4px",
                            flexShrink: 0,
                          }}
                        >
                          {skill.percentage}%
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>

          {/* New Section for Professional Skills */}
          <Grid item xs={12}>
            <Paper style={{ ...cardStyle, padding: "24px" }}>
              <Typography variant="h6" style={headerStyle}>
                المهارات الشخصية والانضباطية
              </Typography>
              <Grid container spacing={2}>
                {disciplineSkills.map((skill, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <Tooltip
                      title={`${skill.skill_name} proficiency level`}
                      arrow
                    >
                      <Box display="flex" alignItems="center">
                        <SkillIcon style={{ cursor: "pointer" }}>
                          {getProfessionalSkillIcon(skill.skill_name)}
                        </SkillIcon>
                        <Typography
                          variant="body1"
                          style={{
                            fontFamily: "Cairo, sans-serif",
                            marginBottom: "4px",
                            color: darkTheme.palette.text.secondary,
                            width: "120px",
                            flexShrink: 0,
                          }}
                        >
                          {skill.skill_name}
                        </Typography>
                        <Box flexGrow={1} mx={2}>
                          <AnimatedProgress
                            variant="determinate"
                            value={skill.percentage}
                          />
                        </Box>
                        <Typography
                          variant="body2"
                          style={{
                            fontFamily: "Cairo, sans-serif",
                            color: darkTheme.palette.text.secondary,
                            marginTop: "4px",
                            flexShrink: 0,
                          }}
                        >
                          {skill.percentage ? `${skill.percentage}%` : "لا توجد قيمة"}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>

          {/* New Section for Leave Requests */}
          <Grid item xs={12}>
            <Paper style={{ ...cardStyle, padding: "24px" }}>
              <Typography variant="h6" style={headerStyle}>
                طلبات الإجازة
              </Typography>
              {leaveRequests.length > 0 ? (
                leaveRequests.map((request, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    mt={2}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleLeaveRequestClick(request)}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Cairo, sans-serif",
                        marginRight: "8px",
                        color: "#6fd943",
                      }}
                    >
                      {`طلب إجازة ${index + 1}`}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        fontFamily: "Cairo, sans-serif",
                        color: darkTheme.palette.text.secondary,
                      }}
                    >
                      ({request.start_date} - {request.end_date})
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: "Cairo, sans-serif",
                    color: darkTheme.palette.text.secondary,
                    marginBottom: "8px",
                  }}
                >
                  هذا المستخدم ليس لديه طلبات إجازة مقبولة لهذا الشهر.
                </Typography>
              )}
            </Paper>
          </Grid>

          {/* New Section for Permissions */}
          <Grid item xs={12}>
            <Paper style={{ ...cardStyle, padding: "24px" }}>
              <Typography variant="h6" style={headerStyle}>
                الاذونات
              </Typography>
              {permissions.length > 0 ? (
                permissions.map((permission, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    mt={2}
                    style={{ cursor: "pointer" }}
                    onClick={() => handlePermissionClick(permission)}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Cairo, sans-serif",
                        marginRight: "8px",
                        color: "#6fd943",
                      }}
                    >
                      {`اذن ${index + 1}`}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        fontFamily: "Cairo, sans-serif",
                        color: darkTheme.palette.text.secondary,
                      }}
                    >
                      ({permission.start_time} - {permission.end_time}) -{" "}
                      {calculateHoursDifference(permission.start_time, permission.end_time)}{" "}
                      ساعات
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: "Cairo, sans-serif",
                    color: darkTheme.palette.text.secondary,
                    marginBottom: "8px",
                  }}
                >
                  هذا المستخدم ليس لديه اذونات مقبولة.
                </Typography>
              )}
            </Paper>
          </Grid>

          {/* New Section for Deductions */}
         {/* Section for Deductions */}
         <Grid item xs={12}>
          <Paper style={{ ...cardStyle, padding: "24px" }}>
            <Typography variant="h6" style={headerStyle}>
              الخصومات
            </Typography>
            {deductions.length > 0 ? (
              deductions.map((deduction, index) => (
                <Box key={index} display="flex" alignItems="center" mt={2}>
                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: "Cairo, sans-serif",
                      marginRight: "8px",
                      color: "red",
                    }}
                  >
                    {`خصم ${deduction.reason}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      fontFamily: "Cairo, sans-serif",
                      color: darkTheme.palette.text.secondary,
                    }}
                  >
                    {`(${deduction.date} - ${deduction.time}) - ${deduction.deduction} جنيه`}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography
                variant="body1"
                style={{
                  fontFamily: "Cairo, sans-serif",
                  color: darkTheme.palette.text.secondary,
                  marginBottom: "8px",
                }}
              >
                لا توجد خصومات لهذا الشهر.
              </Typography>
            )}

            {/* Button to download the monthly report */}
            <Button
              variant="contained"
              color="primary"
              onClick={downloadMonthlyReport}
              style={{
                fontFamily: "Cairo, sans-serif",
                marginTop: "16px",
                backgroundColor: "#6fd943",
              }}
            >
              تحميل التقرير الشهري
            </Button>
          </Paper>
        </Grid>

          {/* New Section for Documents and Certificates */}
          <Grid item xs={12}>
            <Paper style={{ ...cardStyle, padding: "24px" }}>
              <Typography variant="h6" style={headerStyle}>
                الوثائق والشهادات
              </Typography>
              {Array.isArray(employee.certificates) &&
              employee.certificates.length > 0 ? (
                employee.certificates.map((certificate, index) => (
                  <Box key={index} display="flex" alignItems="center" mt={2}>
                    {canPreviewFile(certificate) && (
                      <Button
                        onClick={() => handleOpenDialog(certificate)}
                        variant="contained"
                        color="primary"
                        startIcon={
                          <VisibilityIcon style={{ marginLeft: "8px" }} />
                        }
                        style={{
                          marginRight: "8px",
                          backgroundColor: "#6fd943",
                          fontFamily: "Cairo, sans-serif",
                        }}
                      >
                        معاينة
                      </Button>
                    )}
                    <IconButton
                      href={`https://backedndforelbatt.elbatt.com/${certificate}`}
                      download
                      style={{ color: "#6fd943" }}
                      aria-label="download certificate"
                    >
                      <DescriptionIcon />
                    </IconButton>
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Cairo, sans-serif",
                        marginLeft: "8px",
                      }}
                    >
                      <a
                        href={`https://backedndforelbatt.elbatt.com/${certificate}`}
                        download
                        style={{ color: "#6fd943", textDecoration: "none" }}
                      >
                        تحميل الشهادة
                      </a>
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography
                  variant="body1"
                  style={{
                    color: darkTheme.palette.text.secondary,
                    fontFamily: "Cairo, sans-serif",
                    marginBottom: "8px",
                  }}
                >
                  لا توجد وثائق أو شهادات مسجلة.
                </Typography>
              )}
            </Paper>
          </Grid>
        </Grid>

        {/* Dialog for Leave Request, Permission Details, and Certificate Preview */}
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="md"
          style={{ textAlign: "right" }}
        >
          <DialogTitle
            style={{ fontFamily: "Cairo, sans-serif", textAlign: "center" }}
          >
            {dialogType === "leaveRequest" && "تفاصيل طلب الإجازة"}
            {dialogType === "permission" && "تفاصيل الإذن"}
            {dialogType === "certificate" && "معاينة الشهادة"}
          </DialogTitle>
          <DialogContent style={{ height: "500px" }}>
            {selectedLeaveRequest && (
              <div>
                <Typography
                  variant="body1"
                  style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
                >
                  <span style={keyStyle}>اسم الموظف:</span>{" "}
                  {selectedLeaveRequest.employee_name}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
                >
                  <span style={keyStyle}>القسم:</span>{" "}
                  {selectedLeaveRequest.department}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
                >
                  <span style={keyStyle}>نوع الإجازة:</span>{" "}
                  {selectedLeaveRequest.leave_type}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
                >
                  <span style={keyStyle}>سبب الإجازة:</span>{" "}
                  {selectedLeaveRequest.leave_reason}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
                >
                  <span style={keyStyle}>تاريخ البدء:</span>{" "}
                  {selectedLeaveRequest.start_date}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
                >
                  <span style={keyStyle}>تاريخ الانتهاء:</span>{" "}
                  {selectedLeaveRequest.end_date}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
                >
                  <span style={keyStyle}>تاريخ الإنشاء:</span>{" "}
                  {selectedLeaveRequest.created_at}
                </Typography>
              </div>
            )}
            {selectedPermission && (
              <div>
                <Typography
                  variant="body1"
                  style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
                >
                  <span style={keyStyle}>اسم الموظف:</span>{" "}
                  {selectedPermission.employee_name}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
                >
                  <span style={keyStyle}>القسم:</span> {selectedPermission.department}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
                >
                  <span style={keyStyle}>نوع الإذن:</span>{" "}
                  {selectedPermission.permission_type}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
                >
                  <span style={keyStyle}>سبب الإذن:</span>{" "}
                  {selectedPermission.permission_reason}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
                >
                  <span style={keyStyle}>وقت البدء:</span>{" "}
                  {selectedPermission.start_time}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
                >
                  <span style={keyStyle}>وقت الانتهاء:</span>{" "}
                  {selectedPermission.end_time}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ fontFamily: "Cairo, sans-serif", marginBottom: "8px" }}
                >
                  <span style={keyStyle}>تاريخ الإنشاء:</span>{" "}
                  {selectedPermission.created_at}
                </Typography>
              </div>
            )}
            {currentCertificate && (
              <div>
                <iframe
                  src={`https://backedndforelbatt.elbatt.com/${currentCertificate}`}
                  title="Certificate Preview"
                  width="100%"
                  height="100%"
                  style={{ border: "none" }}
                ></iframe>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDialog}
              style={{ fontFamily: "Cairo, sans-serif" }}
            >
              إغلاق
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
};

// Function to get the appropriate skill icon based on the skill name
const getSkillIcon = (skillName) => {
  switch (skillName) {
    case "WordPress":
      return <WebIcon />;
    case "Graphic Design":
      return <BrushIcon />;
    case "React":
      return <DeveloperModeIcon />;
    case "Node.js":
      return <CodeIcon />;
    case "PHP":
      return <StorageIcon />;
    case "SALLA":
      return <WebIcon />;
    default:
      return null;
  }
};

// Function to get the appropriate professional skill icon based on the skill name
const getProfessionalSkillIcon = (skillName) => {
  switch (skillName) {
    case "حضوره":
      return <EmojiPeopleIcon />;
    case "التزام الوقت":
      return <AccessTimeIcon />;
    case "التعاون":
      return <ThumbUpIcon />;
    case "قيادة الفريق":
      return <LeadershipIcon />;
    case "التواصل":
      return <CommunicationIcon />;
    case "الإبداع":
      return <CreativityIcon />;
    default:
      return null;
  }
};

export default EmployeeDetail;
