import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Box, Paper, List, ListItem, ListItemText, Grid, CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import LeaveRequestForm from './LeaveRequestForm';
import PermissionRequestForm from './PermissionRequestForm';
import axios from 'axios';

// Create RTL cache
const cacheRtl = createCache({
  key: 'muirtl',
  prepend: true,
});

// Create Dark RTL theme
const theme = createTheme({
  direction: 'rtl',
  palette: {
    mode: 'dark', // Enable dark mode
    background: {
      default: '#121212', // Dark background for the app
      paper: '#1E1E1E', // Dark background for paper components
    },
    text: {
      primary: '#FFFFFF', // White text color for better contrast
      secondary: '#BBBBBB', // Light gray for secondary text
    },
    primary: {
      main: '#6fd943', // Primary color for action buttons, etc.
    },
    error: {
      main: '#f44336', // Red color for error messages or rejection
    },
  },
  typography: {
    fontFamily: 'Cairo, sans-serif',
  },
});

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const PermissionsAndLeaves = () => {
  const [permissions, setPermissions] = useState([]);
  const [leaves, setLeaves] = useState([]);

  const query = useQuery();
  const username = query.get('username');

  const fetchPermissions = () => {
    if (username) {
      fetch(`https://backedndforelbatt.elbatt.com/getpermissions.php/?username=${username}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (Array.isArray(data)) { // Ensure that the data is an array
            setPermissions(data);
          } else {
            console.error('Data fetched is not an array:', data);
            setPermissions([]); // Set permissions to an empty array if fetched data isn't an array
          }
        })
        .catch(error => {
          console.error('Error fetching permissions:', error);
          setPermissions([]); // Set permissions to an empty array in case of an error
        });
    }
  };
  

  const fetchLeaves = () => {
    if (username) {
      axios
        .get(`https://backedndforelbatt.elbatt.com/leaveRequest.php?username=${username}`)
        .then(response => setLeaves(response.data))
        .catch(error => console.error('Error fetching leave requests:', error));
    }
  };

  useEffect(() => {
    fetchPermissions();
    fetchLeaves();
  }, [username]);

  const handlePermissionSubmit = (newPermission) => {
    return fetch(`https://backedndforelbatt.elbatt.com/postpermission.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...newPermission,
        username: username,
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setPermissions(prevPermissions => [...prevPermissions, data]);
      })
      .catch(error => console.error('Error submitting permission:', error));
  };

  const handleLeaveSubmit = (newLeave) => {
    return axios
      .post('https://backedndforelbatt.elbatt.com/leaveRequest.php', { ...newLeave, username })
      .then(response => {
        fetchLeaves(); // Re-fetch leave requests after submission
        return response.data;
      })
      .catch(error => {
        console.error('Error submitting leave request:', error);
        throw error;
      });
  };

  const getStatusStyle = (isAccepted) => {
    if (isAccepted === 1) {
      return { backgroundColor: '#2E7D32', color: '#FFFFFF' }; // Green for accepted
    } else if (isAccepted === 0) {
      return { backgroundColor: '#C62828', color: '#FFFFFF' }; // Red for rejected
    }
    return {}; // No specific style for pending
  };

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box p={2} style={{ fontFamily: 'Cairo, sans-serif', backgroundColor: theme.palette.background.default }}>
          <Typography
            style={{ direction: 'rtl', textAlign: 'center', fontFamily: 'Cairo, sans-serif', color: theme.palette.text.primary }}
            variant="h5"
            gutterBottom
          >
            الأذونات والإجازات
          </Typography>
          <Grid container spacing={4}>
            {/* Permissions Section */}
            <Grid item xs={12} md={6}>
              <Paper
                style={{
                  padding: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                }}
              >
                <div>
                  <Typography
                    style={{ direction: 'rtl', textAlign: 'right', fontFamily: 'Cairo, sans-serif', color: theme.palette.text.primary }}
                    variant="h6"
                  >
                    الأذونات السابقة
                  </Typography>
                  <List>
                    {permissions.map((permission, index) => (
                      <ListItem key={index} style={getStatusStyle(permission.is_accepted)}>
                        <ListItemText
                          style={{ direction: 'rtl', textAlign: 'right', color: theme.palette.text.primary }}
                          primary={permission.permission_type}
                          secondary={`${permission.start_time} - ${permission.end_time} | ${
                            permission.is_accepted === 1 ? 'تم القبول' : permission.is_accepted === 0 ? 'تم الرفض' : ''
                          } | تاريخ القرار: ${permission.decision_date || 'غير متاح'}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <PermissionRequestForm onSubmit={handlePermissionSubmit} />
                </div>
              </Paper>
            </Grid>

            {/* Leaves Section */}
            <Grid item xs={12} md={6}>
              <Paper
                style={{
                  padding: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                }}
              >
                <div>
                  <Typography
                    style={{ direction: 'rtl', textAlign: 'right', fontFamily: 'Cairo, sans-serif', color: theme.palette.text.primary }}
                    variant="h6"
                  >
                    الإجازات السابقة
                  </Typography>
                  <List>
                    {leaves.map((leave, index) => (
                      <ListItem key={index} style={getStatusStyle(leave.is_accepted)}>
                        <ListItemText
                          style={{ direction: 'rtl', textAlign: 'right', color: theme.palette.text.primary }}
                          primary={leave.leave_type}
                          secondary={`${leave.start_date} - ${leave.end_date} | ${
                            leave.is_accepted === 1 ? 'تم القبول' : leave.is_accepted === 0 ? 'تم الرفض' : ''
                          } | تاريخ القرار: ${leave.decision_date || 'غير متاح'}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <LeaveRequestForm onSubmit={handleLeaveSubmit} />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default PermissionsAndLeaves;
