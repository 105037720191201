import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CssBaseline, Button } from '@mui/material'; // Import Button from MUI
import bestemployee from "../../images/Rectangle14.png";
import image11 from "../../images/file-hbAaAvYCsvcPImHK8gMixjYb.png";
import image21 from "../../images/file-hbAaAvYCsvcPImHK8gMixjYbhh.png";
import image12 from "../../images/Rectangle10.png";
import image22 from "../../images/Rectangle12.png";
import dack1 from "../../images/Group26.png";
import dack2 from "../../images/Group27.png";
import './Puplichome.css';
import backgroundimage from "../../images/Slide16_9-6.png";

const PublicHome = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/login');
  };

  return (
    <>
      <CssBaseline />
      <div
        style={{
          height: "100vh",
          backgroundImage: `url(${backgroundimage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        {/* Top-right button */}
        <div style={{
          position: "absolute",
          top: "20px",
          right: "20px",
        }}>
          <Button variant="contained" style={{backgroundColor:"#2c3e47",
            fontFamily:"cairo",width:"150px",fontSize:"20px"}} onClick={handleClick}>
            دخـــول
          </Button>
        </div>

        <p
          style={{
            fontFamily: "cairo",
            textAlign: "right",
            color: "#a2ac90",
            position: "absolute",
            fontSize: "13px",
            top: "32%",
            left: "27%",
          }}
        >
          شركة البط تستخدم التكنولوجيا حيث
          <br />
          تعتمد على الذكاء الاصطناعي تسعى
          <br />
          عادة لتطوير حلول تكنولوجية ميتكرة
          <br />
          في كافة المجالات يمكن ل البط
          <br />
          تحسين استراتيجيات التسويق بتحديد
          <br />
          الجمهور المستهدف وتقديم محتوى
          <br />
          إعلاني مخصص يزيد من التفاعل
        </p>

        {/* Other content */}
        <div
          style={{
            backgroundImage: `url(${bestemployee})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            height: "40px",
            width: "175px",
            top: "47%",
            left: "28%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2 style={{ fontSize: "15px", color: "#a2ac90" }}>
            BEST EMPLOYEE
          </h2>
        </div>
        
        <div style={{ display: "flex", top: "47%", position: "relative", left: "24%", justifyContent: "space-evenly", width: "300px" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <img style={{ width: "65px" }} src={image11} alt="image1" />
            <div style={{
              backgroundImage: `url(${image22})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "90px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px"
            }}>
              <p style={{ fontSize: "9px", color: "white", margin: 0 }}>
                BEST EMPLOYEE
              </p>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <img style={{ width: "65px" }} src={image21} alt="image1" />
            <div style={{
              backgroundImage: `url(${image22})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "90px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px"
            }}>
              <p style={{ fontSize: "9px", color: "white", margin: 0 }}>
                BEST EMPLOYEE
              </p>
            </div>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "0",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", maxWidth: "500px" }}>
            <img
              style={{
                width: "400px",
                height: "400px",
                objectFit: "contain",
              }}
              src={dack2}
              alt="dac1"
            />
            <div
              style={{
                height: "75px",
                width: "220px",
                backgroundImage: `url(${image12})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                marginLeft: "-80px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h4 style={{ color: "#a2ac90", fontSize: "20px" }}>G M</h4>
              <p style={{ color: "#a2ac90", fontSize: "14px" }}>Mohamed Hassan</p>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row-reverse", alignItems: "flex-end", maxWidth: "500px" }}>
            <img
              style={{
                width: "400px",
                height: "400px",
                objectFit: "contain",
              }}
              src={dack1}
              alt="dac1"
            />
            <div
              style={{
                height: "75px",
                width: "220px",
                backgroundImage: `url(${image12})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                marginRight: "-80px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h4 style={{ color: "#a2ac90", fontSize: "20px" }}>C E O</h4>
              <p style={{ color: "#a2ac90", fontSize: "14px" }}>Sami El-Metwalie</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(PublicHome) ;
